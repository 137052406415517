<template>
  <layout-base>
    <template v-slot:header><slot name="header"></slot></template>
    <template v-slot:default>
      <div class="workspace bg-panel">
        <div v-if="$slots['tabs'] != null" class="tabs">
          <slot name="tabs"></slot>
        </div>
        <slot name="default"></slot>
      </div>
    </template>
    <template v-slot:footer><slot name="footer"></slot></template>
  </layout-base>
</template>

<script>
import LayoutBase from "@/components/layouts/LayoutBase";
export default {
  name: "LayoutWorkspace",
  components: { LayoutBase }
};
</script>

<style scoped>
.tabs {
  width: 100%;
  margin-bottom: 1em;
}
.tabs /deep/button {
  padding: 0.5em 1em;
  border-radius: 0.5em;
}
.tabs /deep/button:not(:last-child) {
  margin-right: 0.5em;
}
.tabs /deep/button.selected {
  font-weight: bold;
  background-color: var(--color-button-normal);
}
.workspace {
  width: 80vw;
  text-align: center;
  margin: 0 auto 8rem;
  padding: 2em;
}
</style>
