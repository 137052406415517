<template>
  <div>
    <!-- Overall Rating -->
    <ship-graph-combat
      class="mx-auto"
      :ship-types="Object.values(shipTypes)"
      style="width: 40rem;height: 40rem;"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ShipGraphCombat from "@/components/charts/ships/ShipGraphCombat";
export default {
  name: "ShipCategoryCombat",
  components: { ShipGraphCombat },
  computed: {
    ...mapGetters("data", ["shipTypes"])
  }
};
</script>

<style scoped></style>
