export const ShipEvaluator = {
  shipCosts: {},
  getTotalCost(shipType) {
    if (this.shipCosts[shipType.key] != null) {
      return this.shipCosts[shipType.key];
    }
    const cost = shipType.cost;
    let result = 0;
    cost.forEach(e => {
      result += e.value;
    });
    this.shipCosts[shipType.key] = result;
    return result;
  },
  getDefenseRating(shipType) {
    return (
      ((shipType.armor + shipType.shields) / this.getTotalCost(shipType)) * 1000
    );
  },
  getAttackRating(shipType) {
    return (shipType.attack / this.getTotalCost(shipType)) * 1000;
  },
  getCapacityRating(shipType) {
    return (shipType.capacity / this.getTotalCost(shipType)) * 1000;
  },
  getSpeedRating(shipType) {
    return shipType.speed;
  }
};
