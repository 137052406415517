<template>
  <point-chart
    :datasets="chartData"
    :configuration="configuration"
    :x-axis-size="xAxisSize"
    :y-axis-size="yAxisSize"
    :x-axis-label="
      $t('components.graphs.ship.combat.x_axis_label', { n: 1000 })
    "
    :y-axis-label="
      $t('components.graphs.ship.combat.y_axis_label', { n: 1000 })
    "
    ><template v-slot:title>{{
      $t("components.graphs.ship.combat.title")
    }}</template></point-chart
  >
</template>

<script>
import { ShipEvaluator } from "@/services/ShipEvaluator";
import PointChart from "@/components/charts/PointChart";

export default {
  name: "ShipGraphCombat",
  components: { PointChart },
  props: {
    shipTypes: {
      type: Array
    }
  },
  data: () => {
    return {
      configuration: {
        xAxisResolution: 100,
        yAxisResolution: 100
      }
    };
  },
  computed: {
    chartData() {
      return this.shipTypes
        .filter(t => t != null && t.cost.length > 0 && t.attack > 0)
        .map(t => {
          return {
            name: t.name,
            image: t.image,
            x: ShipEvaluator.getDefenseRating(t),
            y: ShipEvaluator.getAttackRating(t)
          };
        });
    },
    xAxisSize() {
      return this.chartData.length > 0
        ? Math.max(...this.chartData.map(d => d.x))
        : 1;
    },
    yAxisSize() {
      return this.chartData.length > 0
        ? Math.max(...this.chartData.map(d => d.y))
        : 1;
    }
  }
};
</script>

<style scoped></style>
