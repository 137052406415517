<template>
  <layout-workspace>
    <template v-slot:header>
      <router-link class="inline-block h-full" to="/">
        <img
          src="@/assets/logos/logo-stats.svg"
          alt="Logo"
          class="mx-auto my-4 inline-block"
          style="height: 5vh;opacity: 0.3;"
      /></router-link>
    </template>
    <template v-slot:tabs v-if="tabs.length > 0">
      <router-link
        :key="tab.id"
        v-for="tab in tabs"
        :to="{ name: tab.routeName }"
      >
        <button
          v-html="tab.name"
          :class="{ selected: tab.category === category }"
        ></button
      ></router-link>
    </template>
    <template v-slot:default>
      <ship-category-combat v-if="category === 'combat'" />
      <ship-category-logistics v-if="category === 'logistics'" />
      <ship-category-gatherers v-if="category === 'gatherers'" />
    </template>
    <template v-slot:footer>
      <disclaimer />
    </template>
  </layout-workspace>
</template>

<script>
import LayoutWorkspace from "@/components/layouts/LayoutWorkspace";
import Disclaimer from "@/components/misc/Disclaimer";
import ShipCategoryCombat from "@/components/categories/ships/ShipCategoryCombat";
import ShipCategoryLogistics from "@/components/categories/ships/ShipCategoryLogistics";
import ShipCategoryGatherers from "@/components/categories/ships/ShipCategoryGatherers";
export default {
  name: "ShipStats",
  components: {
    ShipCategoryGatherers,
    ShipCategoryLogistics,
    ShipCategoryCombat,
    Disclaimer,
    LayoutWorkspace
  },
  props: {
    category: {
      type: String,
      default: "combat-ships"
    }
  },
  data: () => {
    return {
      activeTab: 1
    };
  },
  computed: {
    tabs() {
      return [
        {
          id: 1,
          name: this.$t("views.shipstats.tab.combat"),
          category: "combat",
          routeName: "combat-ships"
        },
        {
          id: 2,
          name: this.$t("views.shipstats.tab.logistics"),
          category: "logistics",
          routeName: "logistics-ships"
        } /*,
        {
          id: 3,
          name: this.$t("views.shipstats.tab.gatherers"),
          category: "gatherers",
          routeName: "gatherer-ships"
        }*/
      ];
    }
  },
  mounted() {
    this.onTabSelected(this.tabs[0]);
    this.updateState();
  },
  methods: {
    onTabSelected(tab) {
      this.activeTab = tab.id;
      switch (tab.mode) {
        case "battle": {
          this.entity = this.getEntity(tab.battleIndex);
          this.fleet = this.getFleet(tab.battleIndex);
          this.report = this.getReport(tab.battleIndex);
          break;
        }
      }
      this.mode = tab.mode;
    },
    updateState() {}
  }
};
</script>

<style scoped></style>
