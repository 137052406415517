<template>
  <chart style="padding-bottom: 2rem;" :configuration="configuration">
    <template v-slot:title><slot name="title"></slot></template>
    <template v-slot:default>
      <div
        :key="'horizontal-line-' + entry.index"
        class="line horizontal absolute w-full left-0"
        v-for="entry in yAxisLines"
        :style="{
          top: (1 - entry.position) * 100 + '%'
        }"
      >
        <span class="label" :title="yAxisLabel">{{ entry.value }}</span>
      </div>
      <div
        :key="'vertical-line-' + entry.index"
        class="line vertical absolute w-full left-0"
        v-for="entry in xAxisLines"
        :style="{
          left: entry.position * 100 + '%'
        }"
      >
        <span class="label" :title="xAxisLabel">{{ entry.value }}</span>
      </div>
      <div
        class="point"
        :key="index"
        v-for="(entry, index) in datasets"
        :style="{
          top: (1 - getYPosition(entry.y)) * 100 + '%',
          left: getXPosition(entry.x) * 100 + '%'
        }"
        :title="
          entry.name +
            ' (' +
            formatNumber(entry.x) +
            '/' +
            formatNumber(entry.y) +
            ')'
        "
        @click="toggleSelected"
      >
        <img v-if="entry.image != null" :src="entry.image" alt="" />
      </div>
    </template>
  </chart>
</template>

<script>
import Chart from "@/components/charts/Chart";
export default {
  name: "PointChart",
  components: { Chart },
  props: {
    datasets: {
      type: Array
    },
    configuration: {
      type: Object,
      default: () => {}
    },
    xAxisSize: {
      type: Number,
      default: 1
    },
    yAxisSize: {
      type: Number,
      default: 1
    },
    xAxisLabel: {
      type: String,
      default: ""
    },
    yAxisLabel: {
      type: String,
      default: ""
    },
    xAxisMode: {
      type: String,
      default: "linear"
    },
    yAxisMode: {
      type: String,
      default: "linear"
    }
  },
  computed: {
    xInterval() {
      if (this.configuration.xAxisResolution != null) {
        return this.configuration.xAxisResolution;
      }
      return this.autoDetectAxisResolution(this.yAxisSize);
    },
    yInterval() {
      if (this.configuration.yAxisResolution != null) {
        return this.configuration.yAxisResolution;
      }
      return this.autoDetectAxisResolution(this.yAxisSize);
    },
    xAxisLines() {
      const result = [];
      switch (this.xAxisMode) {
        case "linear":
        default: {
          for (let i = 0; i < Math.ceil(this.xAxisSize / this.xInterval); i++) {
            result.push({
              index: i + 1,
              value: i * this.xInterval,
              position: (i * this.xInterval) / this.xAxisSize
            });
          }
        }
      }
      return result;
    },
    yAxisLines() {
      const result = [];
      switch (this.yAxisMode) {
        case "log": {
          let index = -1;
          let value = 0;
          while (value < this.yAxisSize) {
            result.push({
              index,
              value,
              position: this.getYPosition(value)
            });
            index++;
            value = Math.pow(10, index);
          }
          break;
        }
        case "linear":
        default: {
          for (let i = 0; i < Math.ceil(this.yAxisSize / this.yInterval); i++) {
            result.push({
              index: i + 1,
              value: i * this.yInterval,
              position: (i * this.yInterval) / this.yAxisSize
            });
          }
          break;
        }
      }
      return result;
    }
  },
  methods: {
    getPosition(value, axisSize, mode) {
      switch (mode) {
        case "log": {
          return Math.pow(value / axisSize, 0.1);
        }
        case "linear":
        default: {
          return value / axisSize;
        }
      }
    },
    getXPosition(value) {
      return this.getPosition(value, this.xAxisSize, this.xAxisMode);
    },
    getYPosition(value) {
      return this.getPosition(value, this.yAxisSize, this.yAxisMode);
    },
    formatNumber(n) {
      return Math.round(n * 100) / 100;
    },
    toggleSelected(event) {
      if (event.target.classList.contains("selected")) {
        event.target.classList.remove("selected");
      } else {
        event.target.classList.add("selected");
      }
    },
    autoDetectAxisResolution(axisSize) {
      if (axisSize <= 1) {
        return 0.25;
      }
      if (axisSize <= 10) {
        return 2;
      }
      if (axisSize <= 100) {
        return 20;
      }
      if (axisSize <= 1000) {
        return 200;
      }
      if (axisSize <= 10000) {
        return 2000;
      }
      if (axisSize <= 100000) {
        return 20000;
      }
      if (axisSize <= 1000000) {
        return 200000;
      }
      if (axisSize <= 10000000) {
        return 2000000;
      }
      if (axisSize <= 100000000) {
        return 20000000;
      }
      if (axisSize <= 1000000000) {
        return 200000000;
      }
      return axisSize / 5;
    }
  }
};
</script>

<style scoped>
.point {
  position: absolute;
  width: 2rem;
  height: 2rem;
  background-color: #222426;
  border: 0.1rem solid #555;
  box-sizing: border-box;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.1s ease-in-out, height 0.1s ease-in-out;
}
.point img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 0;
  height: 0;
  pointer-events: none;
}
.point:hover {
  cursor: pointer;
  z-index: 5;
}
.point:hover::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.1;
  pointer-events: none;
  border-radius: 50%;
}
.point:hover {
  width: 3rem;
  height: 3rem;
}
.point.selected {
  width: 5rem;
  height: 5rem;
  z-index: 1;
}
.point.selected:hover {
  z-index: 6;
}
.point img {
  width: 100%;
  height: 100%;
}

.line {
  border-color: #333333;
}

.label {
  display: block;
  position: absolute;
}

.line.horizontal {
  border-top-width: 0.1rem;
  height: 0;
}

.line.horizontal .label {
  left: -1.5rem;
  top: 50%;
  transform: translate(-100%, -50%);
  text-align: right;
}

.line.vertical {
  border-left-width: 0.1rem;
  height: 100%;
  width: 0;
  border-color: #33333344;
}

.line.vertical .label {
  bottom: -3rem;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
</style>
