import { render, staticRenderFns } from "./ShipCategoryCombat.vue?vue&type=template&id=9d9de270&scoped=true&"
import script from "./ShipCategoryCombat.vue?vue&type=script&lang=js&"
export * from "./ShipCategoryCombat.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d9de270",
  null
  
)

export default component.exports