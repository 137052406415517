var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('chart',{staticStyle:{"padding-bottom":"2rem"},attrs:{"configuration":_vm.configuration},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._t("title")]},proxy:true},{key:"default",fn:function(){return [_vm._l((_vm.yAxisLines),function(entry){return _c('div',{key:'horizontal-line-' + entry.index,staticClass:"line horizontal absolute w-full left-0",style:({
        top: (1 - entry.position) * 100 + '%'
      })},[_c('span',{staticClass:"label",attrs:{"title":_vm.yAxisLabel}},[_vm._v(_vm._s(entry.value))])])}),_vm._l((_vm.xAxisLines),function(entry){return _c('div',{key:'vertical-line-' + entry.index,staticClass:"line vertical absolute w-full left-0",style:({
        left: entry.position * 100 + '%'
      })},[_c('span',{staticClass:"label",attrs:{"title":_vm.xAxisLabel}},[_vm._v(_vm._s(entry.value))])])}),_vm._l((_vm.datasets),function(entry,index){return _c('div',{key:index,staticClass:"point",style:({
        top: (1 - _vm.getYPosition(entry.y)) * 100 + '%',
        left: _vm.getXPosition(entry.x) * 100 + '%'
      }),attrs:{"title":entry.name +
          ' (' +
          _vm.formatNumber(entry.x) +
          '/' +
          _vm.formatNumber(entry.y) +
          ')'},on:{"click":_vm.toggleSelected}},[(entry.image != null)?_c('img',{attrs:{"src":entry.image,"alt":""}}):_vm._e()])})]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }