<template>
  <div class="relative">
    <div class="absolute top-0 left-0 w-full">
      <span class="title"><slot name="title"></slot></span>
    </div>
    <div style="padding-top: 4rem;" class="h-full">
      <div class="relative h-full">
        <slot name="default"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Chart",
  props: {
    configuration: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 2rem;
  font-weight: bold;
}
</style>
